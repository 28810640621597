













































import { Component, Vue } from 'vue-property-decorator';
import { Club } from '../models/club.model'
import { Action, Getter } from 'vuex-class';
import { LoginType } from '../models/login-type.model';
import { LoginTypeEnum } from '../models/login-type.enum';
import { Validate } from 'vuelidate-property-decorators';
import { required, email } from 'vuelidate/lib/validators'
import { ToastHelper } from '@/shared/helpers/toast.helper';

@Component
export default class ResetPassword extends Vue {
  loginTypes: LoginType[] = [
    { type: LoginTypeEnum.FederationMembershipNumber, text: 'Bondsnummer' },
    { type: LoginTypeEnum.ClubMembershipNumber, text: 'Clublidnummer' }
  ];
  selectedLoginType = this.loginTypes[0];

  @Validate({required})
  membershipNumber = '';
  @Validate({required, email})
  emailAddress = '';

  @Getter
  private currentClub!: Club | null;

  @Action
  private resetPassword!: (payload: { fedNumber: string | null, clubNumber: string | null, emailAddress: string}) => Promise<void>

  created(): void {
    if (!this.currentClub) {
      this.$router.push('/').catch(ex => ex);
      return;
    }
    this.checkClubSport();
  }

  checkClubSport(): void {
    if (!this.currentClub!.isHockey) {
      return;
    }
    this.loginTypes = [ this.loginTypes[1] ];
    this.selectedLoginType = this.loginTypes[0];
  }

  async reset(): Promise<void> {
    if (this.$v.$invalid) {
      return;
    }
    const federationMembershipNumber = this.selectedLoginType.type === LoginTypeEnum.FederationMembershipNumber ? this.membershipNumber : null;
    const clubMembershipNumber = this.selectedLoginType.type === LoginTypeEnum.ClubMembershipNumber ? this.membershipNumber : null;

    await this.resetPassword({ fedNumber: federationMembershipNumber, clubNumber: clubMembershipNumber, emailAddress: this.emailAddress });
    ToastHelper.showSuccess(this.$root.$bvToast, this.$t('login.resetRequestSent'))
    this.$router.push('/login');
  }

  get membershipNumberText(): string {
    return this.selectedLoginType.text;
  }

  get logoUrl() : string | null {
    return this.currentClub?.logoUrl ?? null;
  }
}
