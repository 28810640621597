var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "backend login" }, [
    _c("main", [
      _c("div", { staticClass: "row h-100" }, [
        _c(
          "div",
          {
            staticClass: "col-12 col-md-9 mx-auto login-container",
            staticStyle: { "max-width": "500px" },
          },
          [
            _c("form", { staticClass: "form-narrow py-4 col-12 pb-3" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("div", { staticClass: "text-center mb-4" }, [
                    _c("h1", { staticClass: "h3 mb-3 font-weight-normal" }, [
                      _vm._v("Wachtwoord vergeten"),
                    ]),
                    _vm.logoUrl
                      ? _c("img", {
                          staticStyle: { "max-width": "200px" },
                          attrs: { src: _vm.logoUrl },
                        })
                      : _vm._e(),
                    _c("p", { staticClass: "mt-2" }, [
                      _vm._v(" " + _vm._s(_vm.$t("login.resetDescription"))),
                    ]),
                    _c("p", { staticClass: "mt-2" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("login.resetDescriptionBottom"))
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      style: {
                        display: _vm.loginTypes.length > 1 ? "block" : "none",
                      },
                    },
                    [
                      _c("label", [_vm._v("Reset met")]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedLoginType,
                              expression: "selectedLoginType",
                            },
                          ],
                          staticClass: "form-control placeholder-changer",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedLoginType = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(_vm.loginTypes, function (loginType) {
                          return _c(
                            "option",
                            {
                              key: loginType.type,
                              domProps: { value: loginType },
                            },
                            [_vm._v(" " + _vm._s(loginType.text) + " ")]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          state: _vm.$v.emailAddress.$dirty
                            ? !_vm.$v.emailAddress.$error
                            : null,
                          placeholder: _vm.$t("login.emailAddress"),
                        },
                        model: {
                          value: _vm.$v.emailAddress.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.emailAddress,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.emailAddress.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("b-form-input", {
                        attrs: {
                          state: _vm.$v.membershipNumber.$dirty
                            ? !_vm.$v.membershipNumber.$error
                            : null,
                          placeholder: _vm.membershipNumberText,
                        },
                        model: {
                          value: _vm.$v.membershipNumber.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.membershipNumber,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.membershipNumber.$model",
                        },
                      }),
                      _vm.$v.membershipNumber.$error
                        ? _c("b-form-invalid-feedback", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.membershipNumberText) +
                                " is verplicht "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      ref: "resetButton",
                      staticClass: "btn login-button btn-block",
                      attrs: { disabled: _vm.$v.$invalid },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.reset.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("login.reset")))]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "text-small d-block mt-3 text-center go-to-login",
                      attrs: { to: "/login" },
                    },
                    [_vm._v("Terug naar loginpagina")]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }